import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteUser } from "../../slices/user";
import TableContainer from "../../Components/Common/TableContainer";
import { useMemo } from "react";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import {
  CardBody,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Button,
  Input,
  Label,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  getPrivacyPolicy,
  updatePrivacyPolicy,
} from "../../slices/privacypolicy";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import { BUTTON_LIST } from "../../helpers/constnt";
import { marked } from "marked";
import MultiValueInput from "../../Components/Common/MultiValueInput";

const customerstatus = [
  { label: "All", value: "" },
  { label: "Active", value: "active" },
  { label: "Deactivate", value: "inactive" },
];
const PrivacyPolicy = () => {
  document.title = "Privacy and Policy | WriterTools";

  const dispatch = useDispatch();
  const { privacyPolicyCount, privacyPolicyData, privacyPolicyLoader } =
    useSelector((state) => state.PrivacyPolicy);
  const [customerStatus, setcustomerStatus] = useState(customerstatus[0]);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [descriptionData, setDescriptionData] = useState({});
  const [enteredValues, setEnteredValues] = useState([]);
  const [privacyModal, setPrivacyModal] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [rowId, setRowId] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [limit, setLimit] = useState(10);

  const handleOnChangeLimit = (value) => {
    setPage(1);
    setLimit(value);
  };
  const nPages = Math.ceil(privacyPolicyCount / limit);

  const handleOnChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };
  useEffect(() => {
    setPage(1);
  }, [searchValue, customerStatus]);
  useEffect(() => {
    setSearchValue("");
  }, [customerStatus]);
  useEffect(() => {
    const params = {};

    if (customerStatus?.value && customerStatus?.value !== "All") {
      params.isActive = customerStatus.value === "active" ? true : false;
    }

    if (searchValue) {
      params.search = searchValue?.trimEnd();
    }
    if (page) {
      params.page = page;
    }
    if (limit) {
      params.limit = limit;
    }
    if (searchValue) {
      let timer;
      const makeAPICall = () => {
        dispatch(
          getPrivacyPolicy({
            ...params,
          })
        );
      };
      clearTimeout(timer);
      timer = setTimeout(makeAPICall, 1000);
      return () => clearTimeout(timer);
    } else {
      dispatch(
        getPrivacyPolicy({
          ...params,
        })
      );
    }
  }, [customerStatus, searchValue, page, limit]);
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const columns = useMemo(() => [
    {
      Header: "Sr. no",
      accessor: "srno",
      id: "index",
      Cell: (row) => {
        return (
          <>
            <div className="w-sm">{(page - 1) * 10 + row.row.index + 1}</div>
          </>
        );
      },
    },
    {
      Header: "Name",
      accessor: (row) => row?.title ?? "-",

      filterable: false,
    },
    {
      Header: "Description",
      Cell: (cellProps) => (
        <div>
          {
            <span style={{ cursor: "pointer" }}>
              <i
                className="ri-eye-fill align-bottom me-2 text-muted "
                style={{ fontSize: "23px" }}
                onClick={() => {
                  setDescriptionData(cellProps?.row?.original);
                  setPrivacyModal(true);
                }}
              ></i>
            </span>
          }
        </div>
      ),
      filterable: false,
    },
    {
      Header: "Created Date",
      Cell: (cellProps) => (
        <>{handleValidDate(cellProps?.row?.original?.createdAt)}</>
      ),
    },
    {
      Header: "Updated Date",
      Cell: (cellProps) => (
        <>{handleValidDate(cellProps?.row?.original?.updatedAt)}</>
      ),
    },
    {
      Header: "Action",
      Cell: (cellProps) => {
        return (
          <div className="d-flex " style={{ width: "100px" }}>
            <span style={{ cursor: "pointer" }}>
              <i
                className="ri-pencil-fill align-bottom me-2 text-muted "
                style={{ fontSize: "21px" }}
                onClick={() => {
                  handleOnEdit(cellProps?.row?.original);
                }}
              ></i>
            </span>
          </div>
        );
      },
    },
  ]);

  const formValidation = () => {
    let isFormValid = true;
    let newErrors = {};
    const requiredFields = ["title", "content"];

    requiredFields.forEach((field) => {
      if (!values?.title || values?.title.trim() === "") {
        isFormValid = false;
        newErrors["title"] = "Please enter a valid title";
      }
      if (!values?.content || values?.content.trim() === "") {
        isFormValid = false;
        newErrors["content"] = "Please enter a valid content";
      }
    });

    setErrors(newErrors);
    return isFormValid;
  };
  const handleEditorChange = (val) => {
    if (val == "<p><br></p>") {
      setValues((prevState) => ({
        ...prevState,
        content: "",
      }));
    } else {
      setValues((prevState) => ({
        ...prevState,
        content: val,
      }));
    }
  };
  const handleOnEdit = (item) => {
    setRowId(item?._id);
    setValues({
      ...values,
      title: item?.title||"",
      content: item?.content||"",
      metaTitle:item?.metaTitle||"",
      metaDescription:item?.metaDescription||""
    });
    setEnteredValues(item?.metaKeyWords|| [])
    setAddModal(true);
  };

  const handleOnUpdateCategory = () => {
    if (formValidation()) {
      setLoader(true);


      dispatch(
        updatePrivacyPolicy({
          title: values?.title,
          content: values?.content,
          metaTitle: values?.metaTitle,
          metaDescription: values?.metaDescription,
          metaKeyWords:enteredValues,
          id: rowId,
        })
      )
        .then((res) => {
          if (res?.payload?.success) {
            setAddModal(false);
            setLoader(false);
            dispatch(getPrivacyPolicy({ page: page }));
            toast.success("Privacy Policy Updated Successfully");
            setValues({});
          } else {
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast.error(err.response.data.message);
        });
    }
  };

  const handleAddEnteredValue = (newValue) => {
    setEnteredValues([...enteredValues, newValue]);
  };

  const handleRemoveEnteredValue = (valueToRemove) => {
    const filteredValues = enteredValues.filter((value) => value !== valueToRemove);
    setEnteredValues(filteredValues);
  };
  return (
    <>
      <Modal isOpen={privacyModal} fullscreen={true} centered id="exampleModal">
        <ModalHeader
          toggle={() => {
            setPrivacyModal(false);
          }}
        >
          Privacy & Policy
        </ModalHeader>
        <ModalBody>
          <div
            dangerouslySetInnerHTML={{
              __html: marked(descriptionData?.content || "-"),
            }}
          />
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setPrivacyModal(false);
            }}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal isOpen={addModal} centered id="exampleModal">
        <ModalHeader
          toggle={() => {
            setAddModal(false);
            setValues({});
            setErrors({});
          }}
        >
          {rowId ? "Update Privacy and Policy" : "Add Privacy and Policy"}{" "}
        </ModalHeader>
        <ModalBody>
          <form>
            <div className="mb-3">
              <label htmlFor="customer-name" className="col-form-label">
                title: <span className="text-danger">*</span>
                {errors.title && (
                  <span className="text-danger">{errors.title}</span>
                )}
              </label>
              <Input
                type="text"
                className="form-control"
                id="customer-name"
                name="title"
                placeholder="Enter category name"
                value={values.title}
                onChange={handleOnChange}
                readOnly
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Description: <span className="text-danger">*</span>
                {errors.content && (
                  <span className="text-danger">{errors.content}</span>
                )}
              </label>
              <SunEditor
                setOptions={{
                  buttonList: BUTTON_LIST,
                  minHeight: "300px",
                  maxHeight: "600px",
                }}
                onChange={handleEditorChange}
                setContents={values?.content}
                placeholder="Write a detailed description of your Blog"
                name="description"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="metaTitle" className="col-form-label">
                Meta Title
              </label>
              <Input
                type="text"
                className="form-control"
                id="metaTitle"
                name="metaTitle"
                placeholder="Enter Meta Title name"
                value={values.metaTitle}
                onChange={handleOnChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="metaDescription" className="col-form-label">
                Meta Description
              </label>
              <Input
                type="text"
                className="form-control"
                id="metaDescription"
                name="metaDescription"
                placeholder="Enter Meta Description "
                value={values.metaDescription}
                onChange={handleOnChange}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label" htmlFor="meta-keywords-input">
                Meta Keywords
              </Label>
              <Label htmlFor="choices-text-remove-button" className="form-label text-muted"></Label>
              <MultiValueInput
                handleAddEnteredValue={handleAddEnteredValue}
                handleRemoveEnteredValue={handleRemoveEnteredValue}
                enteredValues={enteredValues}
                setEnteredValues={setEnteredValues}
              />
            </div>
          </form>
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="light"
            onClick={() => {
              setAddModal(false);
              setValues({});
              setErrors({});
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            onClick={() => {
              rowId ? handleOnUpdateCategory() : handleOnAddCategory();
            }}
          >
            {loader && (
              <Spinner size="sm" className="flex-shrink-0  ">
                {" "}
                Loading...{" "}
              </Spinner>
            )}
            <span className="flex-grow-1 ms-2">
              {rowId ? "Update Privacy and Policy" : "Add Privacy and Policy"}
            </span>
          </Button>
        </div>
      </Modal>
      <div className="page-content">
        <Row>
          <Col lg={12}>
            <Card id="invoiceList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Privacy and Policy
                  </h5>
                  {privacyPolicyCount > 10 && (
                    <div className="flex-shrink-0">
                      <div className="d-flex gap-2 flex-wrap">
                        Show
                        <select
                          name="pagination"
                          style={{ width: "70px" }}
                          value={limit}
                          onChange={(e) =>
                            handleOnChangeLimit(Number(e.target.value))
                          }
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        entries
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  {privacyPolicyLoader ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      {privacyPolicyData && privacyPolicyData.length > 0 ? (
                        <TableContainer
                          columns={columns || []}
                          data={privacyPolicyData || []}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={limit}
                          customerstatus={customerstatus}
                          setcustomerStatus={setcustomerStatus}
                          customerStatus={customerStatus}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-border"
                          theadClass="table-light text-muted"
                          SearchPlaceholder="Search Privacy Policy..."
                          setSearchValue={setSearchValue}
                          searchValue={searchValue}
                          isPagination={privacyPolicyCount > 10 ? true : false}
                          nPages={nPages}
                          currentPage={page}
                          setCurrentPage={setPage}
                          iscreated={true}
                          totalDataCount={privacyPolicyCount}
                          ispaginationshow={
                            privacyPolicyCount > 10 &&
                            limit < privacyPolicyCount
                              ? true
                              : false
                          }
                        />
                      ) : (
                        <>
                          <TableContainer
                            columns={[]}
                            data={[]}
                            isGlobalFilter={true}
                            customPageSize={0}
                            customerstatus={customerstatus}
                            setcustomerStatus={setcustomerStatus}
                            customerStatus={customerStatus}
                            tableClass="mb-0 align-middle table-border"
                            theadClass="table-light text-muted"
                            SearchPlaceholder="Search Privacy Policy..."
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                            isPagination={false}
                            iscreated={true}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PrivacyPolicy;
